'use client'

import _ from 'lodash'
import {useCallback, useEffect, useLayoutEffect, useState} from 'react'
import SpheresLogo from '../_images/spheres-logo.svg'
import {NAVBAR_CONTAINER_ID} from '~/design-system/hg/modules/HgNavbar/constants'
import NavbarThemeSelector from '~/design-system/hg/modules/HgNavbar/_components/NavbarThemeSelector'
import mobileNavVariants from '~/design-system/hg/modules/HgNavbar/_variants/mobileNavVariants'
import {HgTextLink} from '~/design-system/hg/components'
import {externalLinks} from '~/routing/external-links'

export default function SpheresSubnav() {
  const [isScrolled, setIsScrolled] = useState(false)
  const [pageSections, setPageSections] =
    useState<Array<{id: string; label: string}>>()
  const [activeSection, setActiveSection] = useState<string | null>(null)

  const {bar} = mobileNavVariants({
    isScrolled,
  })

  const checkIfNavbarIsScrolled = useCallback(() => {
    const navbar = document.getElementById(NAVBAR_CONTAINER_ID)
    if (navbar) {
      const {top} = navbar.getBoundingClientRect()
      // Mobile safari can return float values for getBoundingClientRect()
      const hasScrolled = Number(window.scrollY) > top && Math.floor(top) <= 0
      setIsScrolled(hasScrolled)
    }
  }, [])

  useLayoutEffect(() => {
    // check if navbar is initially scrolled
    checkIfNavbarIsScrolled()
  }, [checkIfNavbarIsScrolled])

  useEffect(() => {
    const throttledCheckIfNavbarIsScrolled = _.throttle(
      checkIfNavbarIsScrolled,
      50, // it's ok to delay "scrolled navbar" animation slightly
      {leading: true}
    )

    window.addEventListener('scroll', throttledCheckIfNavbarIsScrolled)

    return () => {
      throttledCheckIfNavbarIsScrolled.cancel()
      window.removeEventListener('scroll', throttledCheckIfNavbarIsScrolled)
    }
  }, [checkIfNavbarIsScrolled])

  useEffect(() => {
    const sections = document.querySelectorAll(
      '#content-without-disclaimers section[id]'
    )
    const sectionData = Array.from(sections).map(section => {
      const id = section.id
      return {
        id,
        label: id.includes('faq') ? 'FAQ' : _.startCase(id),
      }
    })

    // If a subnav section needs to span multiple sections, we can can use the same id but append a suffix to it
    // e.g. speakers for the main section and speakers-continued for the subsequent section
    // We do not want the subsequent sections to show up in the subnav so we filter them out here
    const filteredSectionData = sectionData.filter(({id}) => {
      const idParts = id.split('-')
      const identifier = idParts[0]
      return (
        idParts.length === 1 &&
        sectionData.some(section => section.id !== identifier)
      )
    })

    setPageSections(filteredSectionData)

    function handleIntersection(entries: IntersectionObserverEntry[]) {
      const visibleSections = entries.filter(entry => entry.isIntersecting)
      if (visibleSections.length > 0) {
        // For sections that are split into multiple parts, we want to continue the same active section visible in the subnav
        const firstVisibleSection = visibleSections[0]
        const sectionId = firstVisibleSection.target.id.split('-')[0]
        const mainSection = Array.from(sections).find(section =>
          section.id.includes(sectionId)
        )
        setActiveSection(mainSection?.id ?? null)
      } else {
        setActiveSection(null)
      }
    }
    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '-50% 0px -50% 0px',
      threshold: [0, 1],
    })
    sections.forEach(section => {
      observer.observe(section)
    })
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <>
      <div
        data-section="spheres-subnav"
        className="sticky inset-0 top-navbar-height z-[calc(var(--navbar-z-index)-1)] -mb-[calc(var(--navbar-height)+48px)]"
      >
        <NavbarThemeSelector id="spheres-subnav">
          <nav
            className={bar({
              className:
                '!h-48 !min-h-48 w-screen border-y border-border-frosted py-s2',
            })}
          >
            <HgTextLink
              variant="revealUnderline"
              href="#top"
              data-analytics-event-name="spheres-top"
              className="hidden sm:block"
            >
              <span className="sr-only">Go to the top of the page</span>
              <SpheresLogo />
            </HgTextLink>
            {pageSections && (
              <div className="flex gap-s2">
                {pageSections.map(({id, label}) => (
                  <div key={id} className="relative flex items-center">
                    <HgTextLink
                      href={`#${id}`}
                      className="peer p-8 arcadia-ui-2"
                      variant="revealUnderline"
                      data-analytics-event-name="spheres-subnav-link"
                      aria-current={activeSection === id}
                    >
                      {label}
                    </HgTextLink>
                    <div className="absolute h-4 w-4 rounded-full bg-text-default opacity-0 transition-opacity duration-300 peer-aria-[current=true]:opacity-100" />
                  </div>
                ))}
              </div>
            )}
            <HgTextLink
              variant="revealUnderline"
              className="arcadia-ui-2"
              href={externalLinks.spheres.tickets}
              data-analytics-event-name="spheres-tickets"
              newTab
            >
              Tickets
            </HgTextLink>
          </nav>
        </NavbarThemeSelector>
      </div>
      <div id="top" />
    </>
  )
}
